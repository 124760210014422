export const district =  [
    { key: 'DISTRICT', value: '' },

    { key: 'Ariyalur', value: 'Ariyalur' },
    { key: 'Chengalpattu', value: 'Chengalpattu' },
    { key: 'Chennai', value: 'Chennai' },
    { key: 'Coimbatore', value: 'Coimbatore' },
    { key: 'Cuddalore', value: 'Cuddalore' },
    { key: 'Dharmapuri', value: 'Dharmapuri' },
    { key: 'Dindigul', value: 'Dindigul' },
    { key: 'Erode', value: 'Erode' },
    { key: 'Kallakurichi', value: 'Kallakurichi' },
    { key: 'Kanchipuram', value: 'Kanchipuram' },
    { key: 'Kanniyakumari (Kanyakumari)', value: 'Kanniyakumari' },
    { key: 'Karur', value: 'Karur' },
    { key: 'Krishnagiri', value: 'Krishnagiri' },
    { key: 'Madurai', value: 'Madurai' },
    { key: 'Mayiladuthurai', value: 'Mayiladuthurai' },
    { key: 'Nagapattinam', value: 'Nagapattinam' },
    { key: 'Namakkal', value: 'Namakkal' },
    { key: 'Nilgiris', value: 'Nilgiris' },
    { key: 'Perambalur', value: 'Perambalur' },
    { key: 'Pudukkottai', value: 'Pudukkottai' },
    { key: 'Ramanathapuram', value: 'Ramanathapuram' },
    { key: 'Ranipet', value: 'Ranipet' },
    { key: 'Salem', value: 'Salem' },
    { key: 'Sivaganga', value: 'Sivaganga' },
    { key: 'Tenkasi', value: 'Tenkasi' },
    { key: 'Thanjavur', value: 'Thanjavur' },
    { key: 'Theni', value: 'Theni' },
    { key: 'Thoothukudi (Tuticorin)', value: 'Thoothukudi' },
    { key: 'Tiruchirappalli (Trichy)', value: 'Tiruchirappalli' },
    { key: 'Tirunelveli', value: 'Tirunelveli' },
    { key: 'Tirupathur', value: 'Tirupathur' },
    { key: 'Tiruppur', value: 'Tiruppur' },
    { key: 'Tiruvallur', value: 'Tiruvallur' },
    { key: 'Tiruvannamalai', value: 'Tiruvannamalai' },
    { key: 'Tiruvarur', value: 'Tiruvarur' },
    { key: 'Vellore', value: 'Vellore' },
    { key: 'Viluppuram', value: 'Viluppuram' },
    { key: 'Virudhunagar', value: 'Virudhunagar' },
    

]