

export const LOGIN = 'LOGIN'

export const LOGOUT ='LOGOUT'

export const EXP_AGE = 'EXP_AGE'

export const UPDATEJOBS = "UPDATEJOBS"
export const REMOVEEXP_AGE = "REMOVEEXP_AGE"
export const ISPERMISSION = "ISPERMISSION"
export const CACELPERMISSION = "CACELPERMISSION"
export const INSTRUCTION_READ = "INSTRUCTION_READ"

